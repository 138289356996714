/**
	* @file
	* Custom scripts for theme.
	*/
	window.onerror = function(){
	return true;
};

(function ($) {		
	// https://www.drupal.org/node/1108302	
	// https://www.drupal.org/docs/7/api/javascript-api/javascript-api-overview
	Drupal.behaviors.gmap = {
		attach: function (context, settings) {
			$('.near-you li a').each(function(i){
				$(this).bind('click', function() {
					google.maps.event.trigger(Drupal.settings.gmap.auto1map.markers[i].marker, 'click');
					return false;
				});
				$(this).bind('mouseover', function() {
					google.maps.event.trigger(Drupal.settings.gmap.auto1map.markers[i].marker, 'mouseover');
					return false;
				});
				$(this).bind('mouseout', function(){
					google.maps.event.trigger(Drupal.settings.gmap.auto1map.markers[i].marker, 'mouseout');
					return false;
					});
			});
			}
	};
	
	$(document).ready(function() {
		$('form').each(function( index ) {
		  $(this).attr('name', 'formNo' + index);
		});
		$('nav').each(function( index ) {
		  $(this).attr('name', 'navNo' + index);
		});		
		
		
		var scroll = 0;
		var navST = 10;//distance from top of page
		var classlist = document.getElementsByClassName("st-nav, iwantto, nav-container, alert-site");
		// window.onscroll = function () {
		// 	if ((document.documentElement.scrollTop || document.body.scrollTop> navST) && scroll == 0) {
		// 		classlist.classList.add("fixed");
		// 		scroll = 1;
		// 	}
		// 	else if (document.documentElement.scrollTop || document.body.scrollTop <= navST && scroll == 1) {
		// 		classlist.classList.remove("fixed");
		// 		scroll = 0;
		// 	}
		// };
		

		$(window).scroll(function() {
			var scroll_distance = $(document).scrollTop();
			if (scroll_distance > 1.5) {
				$('.st-nav, .iwantto, .nav-container, .alert-site').addClass('fixed');
			}
			else {
				$('.st-nav, .iwantto, .nav-container, .alert-site').removeClass('fixed');
			}
		});
		// EXPAND ACCORDION OR FAQ by hash - #acc1 #faq1	
		if(window.location.hash && window.location.hash.indexOf('/') == -1) {
			setTimeout(function(){ 
				jQuery("a[href='"+window.location.hash+"']").css("height", "auto");
				jQuery(window.location.hash).addClass('in').css("height", "auto");
			}, 500);
		}
		
		// TABS
		if(jQuery("#newTabs").length > 0) {
			jQuery("#newTabs fieldset").hide(); //Hide all content
			if(window.location.hash && window.location.hash.indexOf('#tab') == 0) {
				var thehash=window.location.hash;
				jQuery(thehash+"-link").addClass("selected"); //Activate first tab
				jQuery(thehash).show(); //Show first tab content	
			} else {
				jQuery("#newTabs .horizontal-tabs-list li:first").addClass("selected"); //Activate first tab
				jQuery("#newTabs .horizontal-tabs-panes fieldset:first").show(); //Show first tab content	
			}
			jQuery("#newTabs .horizontal-tabs-list li").click(function() {
				jQuery("#newTabs .horizontal-tabs-list li").removeClass("selected"); //Remove any "active" class
				jQuery(this).addClass("selected"); //Add "active" class to selected tab
				jQuery("#newTabs fieldset").hide(); //Hide all tab content
				var activeTab = jQuery(this).find("a").attr("href"); //Find the rel attribute value to identify the active tab + content
				jQuery(activeTab).fadeIn(); //Fade in the active content
				return false;
			});
		}
		
		//On Click Event
		$("#tabs li").click(function() {
			$("#tabs li").removeClass("active"); //Remove any "active" class
			$(this).addClass("active"); //Add "active" class to selected tab
			$("#tabs div").hide(); //Hide all tab content
			var activeTab = $(this).find("a").attr("href"); //Find the rel attribute value to identify the active tab + content
			$(activeTab).fadeIn(); //Fade in the active content
			return false;
		});
							
		//added focus on search button / form click
		$('#searchModal').on('shown.bs.modal', function () {
			$('#search-larimer').focus();
		})
		$('[data-toggle="popover"]').popover();  
		$('[data-toggle="tooltip"]').tooltip();  
		$('#myModal').modal();
		$('.collapse').collapse();
		$('.dropdown-toggle').dropdown();
		$('[data-tooltip="false"]').tooltip('hide');
		$('.totop').click(function(){
			$('html, body').animate({scrollTop : 0},800);
			return false;
		});			
		$('.carousel').carousel({
			interval: 8000
		});
		
		if(!$('.alert-site').is(':empty') && $('.alert-site').is(':visible')) {
			$('.st-nav.fixed').addClass('alert-space');
		} else {
			$('.st-nav.fixed').removeClass('alert-space');
		}
	
		// Municode alert for IE7 & older
		$("a[href*='municode.com']").each(function() {
			$(this).click(function(event) {
				var myNav = navigator.userAgent.toLowerCase();
				var IEversion = (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
				if (IEversion && IEversion < 10) {
					alert('You are using Internet Explorer 9 or older. The website where we host our County Codes, municode.com, requires IE 10 or newer. To view it, please upgrade your IE version or use another browser (Chrome, Firefox, etc.).');
					return false;
				}
			});
		}); 
			
		// SET & CHECK COOKIE FOR CLOSURE NOTICE
		createLCCookie = function(name,value,days) {
			$.cookie(name, value, { expires: days }); 
			console.log(name+" "+value+" "+days);
		};
		eraseLCCookie = function(name) {
			$.removeCookie(name);
		};
		if ($.cookie('departmentclosurenotice')) {
			$('#department_closure_notice').hide();
		}
		if ($.cookie('closurenotice')) {
			$('#closure_notice').hide();
		}
		if ($.cookie('emergencynotice')) {
			setTimeout(function(){ 
				$('.alert-site').collapse('toggle');
				$('.alert-close').collapse('toggle');
			}, 1000);
		}
		$(".alert-close .close, .alert-site .close").click(function(){
			$('.alert-close').collapse('toggle');
		});		
		
	});				
	
	$(function() {		
		//  Add class for Safari on Mac 
		if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Mac') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
			$('html').addClass('safari-mac'); // provide a class for the safari-mac specific css to filter with
		}
		
		function ShowPopupAudio (template) {
			var sAttribs = "location=no,buttons=no,toolbar=no,scrollbars=yes,width=400,height=200,resizable=yes,left=50,top=50";
			HelpWindow = window.open (template, "Popup", sAttribs);
		};
	
		// I WANT TO MENUS
		$(".iwant1 li").click(function(){
			$(".iwant1 li, .iwant2 li, .iwant3 ul.rootlist > li, .iwant2 .bg-icon").removeClass("active");
			$(this).addClass("active");
			var index = $(this).index();
			$(".iwant2 li:eq("+index+")").addClass("active");
			$(".iwant2 .bg-icon"+index).addClass("active");
			$(".iwant3 ul.rootlist > li:eq("+index+")").addClass("active");
			//				}, function(){ // mouseout
		});		
		$(".iwant-btn").click(function(){
			$('.iwantto').toggle(400); 
			return false;
		});
		$(".fancybox").fancybox();
		$(".iframe").fancybox({
			hash : false,
			iframe : {
				preload : false
			}
		});
				
		// if id exists, reload img id="refreshImg" every minute
		var id='refreshImg';
		if($("#" + id).length != 0) {
			var img = document.getElementById(id);
			var imgSrc = img.getAttribute('src');
			var refreshImg = document.getElementById(id);
			function getImg() {
				refreshImg.src = imgSrc+'?v=' + Math.random();
			};
			setInterval(getImg, 60000);
		}
	
		// opens PDF links in new window; or browser alerts for PDF forms
		$("a[href*='.pdf']").each(function() {
			var pdfforms = [
			"vendor_application_staff.pdf", 
			"2013_shortform.pdf", 
			"2013_longform.pdf", 
			"VTimeSheet.pdf", 
			"VTimeSheetPassword.pdf",
			"exiting_employee_checklist_lchr-003.pdf", 
			"fw4.pdf", 
			"lc_waiver_final_10-8-19_-_fillable.pdf", 
			"lcfi-106i.pdf", 
			"lcfi-107i.pdf",
			"lcfi-110i.pdf", 
			"lcfi-111i.pdf", 
			"lcfi-112i.pdf", 
			"lcfi-115i.pdf", 
			"lcfi-116i.pdf", 
			"lcfi-117i.pdf", 
			"lcfi-119i.pdf", 
			"lcfi-15i.pdf", 
			"lcfi-18i.pdf", 
			"lcfi-25.pdf", 
			"lcfi-26.pdf", 
			"lcfi-27a.pdf", 
			"lcfi-27b.pdf", 
			"lcfi-2i.pdf", 
			"lcfi-37i.pdf", 
			"lcfi-39i.pdf", 
			"lcfi-4i.pdf", 
			"lcfi-53i.pdf", 
			"lcfi-5_1.pdf", 
			"lcfi-8i.pdf", 
			"lcfitd-12i.pdf", 
			"lcim-5.pdf", 
			"lcim-69i_1.pdf", 
			"lcim-66i_1.pdf", 
			"lcim-68i.pdf", 
			"lcbc-1i.pdf", 
			"lchr-046_guilding_principles_cash_bonus_request_form_fillable.pdf", 
			"lchr-046_guilding_principles_cash_bonus_request_form_fillable_1.pdf", 
			"lchr-12i.pdf", 
			"lchr-17i.pdf", 
			"2019_real_protest_form.pdf", 
			"ds-056-general-personal-property.pdf", 
			"ds-058-renewable-energy-property.pdf", 
			"ds-060-lessor-personal-property.pdf", 
			"ds-155-residential-personal-property.pdf", 
			"ds-658-oil-gas-real-personal-property.pdf", 
			"648-2019_earthstone_writable.pdf", 
			"perspropaddresschng2019.pdf", 
			"ownersrequesttopurge.pdf", 
			"lendersrequesttopurge.pdf", 
			"abatement_1year.pdf", 
			"abatement_2year.pdf", 
			"ag_application.pdf", 
			"wh-380-e_0.pdf", 
			"wh-380-f_0.pdf", 
			"wh-381_0.pdf", 
			"WH-384.pdf", 
			"WH-385.pdf", 
			"wh385V.pdf"	
				];
			var print_only_forms = ["residential_permit_application.pdf", "commercial_permit.pdf", "sign_application.pdf"];
			var pdfprint = ["civil_union_absentee_affidavit.pdf", "Civil_Union_License_Application.pdf", "refund_claim_form.pdf", "vendor_application.pdf",  "WorkersCompWaiver.pdf", "contractor_license_renewal_form.pdf", "lcfi-105.pdf", "contractor_license_application.pdf", "mechanical_application.pdf", "jobber_application.pdf", "specialized_application.pdf", "WorkersCompWaiver.pdf", "refund_claim_form.pdf"];
			var pdfnoprntbtn = ["sgcp_application_instructions_2017.pdf", "sgcp_application_cover_form.pdf"];
			var pdfprintemail = ["artist_application.pdf", "VBA-21-22-ARE.pdf", "vba-21-686c-are.pdf", "vet26-1880.pdf", "sf180.pdf", "w9.pdf", "Emerging_Industries_Application.pdf", "ag_application.pdf", "10ez.pdf", "vba-21-526-are.pdf", "vba-21-534-are.pdf", "escrow_registration.pdf", "remote_access_agreement.pdf", "aiim_referral_form.pdf", "3902_volunteer_ref.pdf", "cgh_application.pdf"];
			var pdfemail = ["one_day_permit.pdf", "row_permit.pdf", "access_permit.pdf", "special_permit.pdf", "4h_entry_form.pdf", "4h_horse_entry_form.pdf", "master_gardener_volunteer.pdf", "open_entry_form2013save.pdf", "camping_request.pdf", "naturalist_questions.pdf", "vra_screening.pdf"];
			var pdfsubmit = ["volunteer_application.pdf", "volunteer_evaluation.pdf"];
			var allpdfforms = ["thispdfwillneverbefound.pdf"];
			allpdfforms = allpdfforms.concat(pdfforms, pdfprint, pdfprintemail, pdfemail, pdfnoprntbtn);
			$(this).click(function(event) {
				event.preventDefault();
				event.stopPropagation();
				var thefile = this.href.substring(this.href.lastIndexOf( '/' ) + 1);
				if (~$.inArray(thefile, allpdfforms)) {
					var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
					var isFirefox = typeof InstallTrigger !== 'undefined';
					var isChrome = !!window.chrome && !isOpera;         
					//				var isIE = @cc_on!@false || !!document.documentMode; 
					//				var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
					openit = 1;
					if (~$.inArray(thefile, pdfemail)) {
						var formtype = "email";
						} else if (~$.inArray(thefile, pdfprintemail)) {
						var formtype = "print-email";
						} else if(~$.inArray(thefile, pdfprint)) {
						var formtype = "print";
						} else if(~$.inArray(thefile, pdfnoprntbtn)) {
						var formtype = "noprntbtn";
						} else if (~$.inArray(thefile, pdfforms)) {
						var formtype = "form";
					}
					var msg = "Browsers no longer allow users to save fillable PDFs.\n\nIf you need to save this form, click the download arrow or click CTRL+S. Open the file on your computer using a PDF viewer like Adobe Acrobat Reader. Make your changes, save and print.\n\nClick Ok to continue.";
					if(formtype.indexOf("noprntbtn") >= 0) {
						msg = "Please use the print button in the Acrobat toolbar and NOT the browser print menu item."; 	
						} else if ( isFirefox === true && formtype.indexOf("noprntbtn") == -1) {
						msg = "Firefox does not support filling in PDF form fields. \n\nOn top of the following PDF page, click the 'Open With Different Viewer' button, then \nOpen with - Other - and choose Adobe Acrobat or Internet Explorer.";
						} else if ( isChrome === true) {
						if(formtype.indexOf("email") >= 0 && formtype.indexOf("print")==-1) {
							msg = "The Send Email button in the following PDF form won't work in Chrome. \n\nWe recommend saving the PDF (CTRL + S). Then right-click on the file and select 'Open with' and choose Acrobat Reader or Internet Explorer. \n\nThen use the Send Email button in the PDF.";	
							} else if(formtype.indexOf("email") >= 0) {
							msg = "The Print and Email buttons in the following PDF form won't work in Chrome. \n\nWe recommend saving the PDF (CTRL + S). Then right-click on the file and select 'Open with' and choose Acrobat Reader or Internet Explorer. \n\nThen use the Email button in the PDF.";	
							} else if (formtype.indexOf("print") >= 0) {
							msg = "The Print button in the following PDF form doesn't work in Chrome. \n\nPlease use the print button in the Acrobat toolbar and NOT the browser print menu item."; 
							} else if (formtype.indexOf("submit") >= 0) {
							msg = "The Submit button in the following PDF form won't work in Chrome. \n\nWe recommend saving the PDF (CTRL + S). Then right-click on the file and select 'Open with' and choose Acrobat Reader or Internet Explorer. \n\nThen use the Submit button in the PDF.";	 
						}
						} else {
						if(formtype.indexOf("email") >= 0) {
							msg = msg + "\n\nClick the Email button to send us the form.";
						}
					}
					alert(msg);
					if(openit) { 
						window.open(this.href, '_blank','noopener');				
					}
					} else if (~$.inArray(thefile, print_only_forms)) {
					var msg = "Please print out and bring in the completed application form with your plans to  the Building Department.  Electronic applications and plans cannot be accepted.";
					openit = 1;
					alert(msg);
					if(openit) { 
						window.open(this.href, '_blank','noopener');				
					}
					
				} else {
					window.open(this.href, '_blank','noopener');
				}
			}).addClass('externalLink');
		});
	
		// opens external HTTP links in new window
		//		$("a[href*='://']:not('.iframe'):not([href*='"+location.hostname.replace+("www.","")+"'], [href*='.pdf'])").each(function() { 
		if(!navigator.userAgent.indexOf('Chrome')) {
			$("a[href*='.pdf'])").each(function() {
				$(this).click(function(event) {
					event.preventDefault();
					event.stopPropagation();
					window.open(this.href, '_blank');
					return false;
				}).addClass('externalLink');
			});	
		}
		$("a[href*='://']:not('.iframe'):not([href*='"+location.hostname.replace
		("www.","")+"'])").each(function() {
			$(this).click(function(event) {
				// console.log('other click function hit');
				event.preventDefault();
				event.stopPropagation();
				window.open(this.href, '_blank');//,'noopener'
				return false;
			}).addClass('externalLink');
		});	
		// opens contact form in modal 
		$("a[href^='/contact?target=']:not(a[href*='contact-']), 	a[href^='/contact/?target=']:not(a[href*='contact-']), a[href*='larimer.gov/contact?target=']:not(a[href*='contact-']), a[href*='larimer.gov/contact/?target=']:not(a[href*='contact-'])").each(function() {
			$(this).addClass('contact-modal');
		});
		function getURLParameter(url, name) {
			return (RegExp(name + '=' + '(.+?)(&|$)').exec(url)||[,null])[1];
		};
		$("a.contact-modal").click(function(e){
			e.preventDefault();
			var url = $(this).attr('href');
			var email = '';
			var subject = '';
			var file = '';
			if(getURLParameter(url, 'target')) {
				var target=getURLParameter(url, 'target');
				if(target.indexOf('(AT)') !== -1) {
					email = target.replace('(AT)','@');
					email = email.replace('(AT)','@');
				} else if(target.indexOf('c%40') !== -1) {
					email = target.replace('%28c%40%29','@');
					email = email.replace('%28c%40%29','@');
				} else if(target.indexOf('%28AT%29') !== -1) {
					email = target.replace('%28AT%29','@');
					email = email.replace('%28AT%29','@');
				} else {
					email = target;
				}
				email = decodeURI(email);
				email = email.replace(/%2C/g,',');
				email = email.replace(/\(/g,'').replace(/\)/g,'');
				$("#edit-submitted-target").val(email);
				if(email=="hae-resourcerequest@co.larimer.co.us") {
					$('.webform-component--message label').html("Please provide information on your current situation and need for resources. *");	
				}
			}
			if(getURLParameter(url, 'subject')) {
				subject = getURLParameter(url, 'subject');
				subject = decodeURI(subject);
				$("#edit-submitted-subject").val(subject);
			}
			if(getURLParameter(url, 'file')) {
				file = getURLParameter(url, 'file');
				file = decodeURI(file); // show attachment in modal with &file=1
				if(file==1) {
					$('.webform-component-file').removeClass("hidden");
				}
			}
			$('#contactModal').modal('show');
			//console.log(target);
			//Hide protected emails
			var protectedemails = [
				'bernard.birnbaum@uchealth.org',
				'bdelgrosso@dominos-now.com',
				'leejennijo@yahoo.com',
				'tjvangoor@gmail.com',
				'west.kowalski@gmail.com'
			];
			if(jQuery.inArray(target, protectedemails) !== -1) {
				$('.webform-component--target label').hide();
				$('#edit-submitted-target').prop({type:"hidden"});
			}
			var emergency=['sheriff', 'hodgeslr', 'sabosn', 'parrottck', 'smithjr', 'humphre', 'fox', 'hand'];
			emergency.forEach(function(value) {
				if(target.indexOf(value)!==-1) {
					$('#emergencynote').removeClass('hidden');
					return; 
				}
			});
//			var elected=['kefala', 'johnso', 'donnel', 'overbe', 'myers', 'wilker', '8thdist', 'josey', 'sheriff', 'surveyor', 'bcc', 'bocc', 'assessor', 'clerk', 'coroner', 'treasurer', 'sheriff', 'surveyor', 'kefalas', 'stephen', 'mcnall', 'overbe', 'myers', 'hanks', '8thdist', 'josey', 'feyen'];
			var elected=['kefalajm', 'jkefalas', 'kstephens', 'stephekm', 'shaddujl', 'jshadduckmcnally', 'JShadduckMcNally', 'bocc'];
			$('.webform-component--mark-private').addClass('hidden');
			elected.forEach(function(value) {
				if(target.indexOf(value)!==-1) {
					$('.webform-component--transparency').show();
					$('.webform-component--mark-private').show(); // removeClass('hidden')
					return; 
				}
			});			
		});
		
		$("a[href^='mailto']").click(function(e){
			e.stopPropagation();
			var url = $(this).attr('href');
			var mailto = url.replace("mailto:", "").split("?");
			var email = mailto[0];
//			console.log(email);
			$("#edit-submitted-target").val(email);
			if(mailto[1]!== 'undefined') {
				var subjectNbody=mailto[1].split("&");
				$("#edit-submitted-subject").val( subjectNbody[0]).replace("subject=", "");
				if(subjectNbody[1]!== 'undefined') {
					$("#edit-submitted-message").val(subjectNbody[1]).replace("body=", "");
				}			
			}
			if(getURLParameter(url, 'file')) { 
			} else {
				$('.edit-submitted-attach-a-file-ajax-wrapper').hide();
			}
			$('#contactModal').modal('show');
			return false;
		});		
		
	}); 
	$(document).ready(function() {
		// remove spam link
		$("a[href*='spatini']").each(function() {
			$(this).addClass("no-icon");
		});
	});
	
})(jQuery);